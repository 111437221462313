/* tslint:disable */

@font-face {
  font-family: "Calibri";
  src: url("../assets/fonts/Calibri/Calibri_Bold.TTF") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Bold_Italic.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Italic.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Light.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Light_Italic.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

html,
body,
button,
input,
optgroup,
select,
textarea,
html .p-inputtext,
.p-component {
  font-family: "Calibri", Helvetica, sans-serif !important;
}

.p-password input {
  width: 100%;
}
.border-l {
  border-left-width: 1px;
}

.countryCode .p-dropdown {
  height: 50px;
  width: 100%;
}

/* .p-dropdown {
  width: 100%;
} */

.password-eye > i {
  line-height: 1;
}

.p-button:focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav {
  background: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: none;
  border-color: #3b82f6;
  color: #3b82f6;
}

/* .p-tabview .p-tabview-panels {
  margin-top: 15px;
} */

.p-menu .p-menuitem-link {
  padding: 18.3px 1.25rem;
}

.card-profile .scrollable-text {
  max-height: 3.4rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}
.card-profile .scrollable-text::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.filter-custom-width {
  min-width: 120px !important;
}

.site-loader .p-progress-spinner-circle {
  stroke: #1581ed !important;
  animation: none !important;
  stroke-width: 4px !important;
}

.theme-button:hover {
  background: #1581ed !important;
  border-color: #1581ed !important;
}

.theme-outlined {
  border-color: #1581ed !important;
  color: #1581ed !important;
}

.header-split-button .p-menuitem-icon,
.header-split-button .p-menuitem-text {
  @apply text-text-Global !important;
  @apply font-semibold !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #1581ed !important;
  background: #1581ed !important;
}

.p-checkbox .p-checkbox-box:hover {
  border-color: #1581ed !important;
}
.policy-review .p-breadcrumb {
  border: none;
}
.policy-review-table .edit-number-column-cell input {
  @apply py-0 !important;
  @apply px-1 !important;
  @apply text-right !important;
  @apply w-32 !important;
}
.policy-review-table
  .p-datatable
  .p-datatable-thead
  > tr
  > th.p-align-center
  > .p-column-header-content {
  @apply w-full !important;
  @apply text-center !important;
}

.policy-review-table .p-datatable-tbody > tr > td {
  @apply h-11 !important;
}
.policy-review-table .p-calendar {
  @apply h-7 !important;
}
.policy-review-table .p-datatable .p-datatable-thead > tr > th {
  @apply min-w-220 !important;
}

.policy-review-table .p-dropdown {
  @apply h-7 !important;
  @apply w-full !important;
  @apply items-center !important;
}

.policy-review-table .p-column-header-content span,
.plan-split-dollar-table .p-column-header-content span {
  @apply w-full !important;
  @apply text-center !important;
}

.policy-review-table .p-datatable-tbody > tr > td,
.plan-split-dollar-table .p-datatable-tbody > tr > td {
  @apply h-11 !important;
}

.policy-review-table .p-column-header-content,
.plan-split-dollar-table .p-column-header-content {
  @apply whitespace-nowrap !important;
}
/* .common-custom-styles {
  @apply bg-switch-button-bg !important;
  @apply rounded-lg !important;
  @apply text-layoutBlue !important;
  @apply flex-row-reverse !important;
}

.common-custom-styles .p-menuitem-text {
  @apply text-layoutBlue !important;
  @apply whitespace-nowrap !important;
}

.common-custom-styles .p-menuitem-icon {
  @apply text-layoutBlue !important;
  @apply mr-0 !important;
  @apply ml-2 !important;
}
.switch-to-agency {
  @apply pr-4 !important;
}
.header-split-button .common-custom-styles:hover {
  @apply bg-switch-button-bg !important;
}

*/

#custom-input-color::-moz-color-swatch {
  border: none;
  border-radius: 30% !important;
}

#custom-input-color::-webkit-color-swatch {
  border: 2px solid #ced4da;
  border-radius: 30% !important;
}

#custom-input-color {
  width: 32px;
  height: 36px;
  appearance: none;
  border-radius: 30% !important;
}

.p-datepicker-trigger.p-button-icon-only {
  color: unset;
}
