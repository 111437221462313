@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  .set-h-3 input {
    @apply h-12;
  }
  .set-w-3 input {
    @apply w-12;
  }
  .invite-modal-agents .p-confirm-dialog-message {
    @apply ml-0 !important;
  }
  .sidebar .p-sidebar-mask {
    @apply relative;
  }
  .sidebar .p-sidebar-mask .p-sidebar {
    @apply w-full;
  }
  .set-w-3 input {
    @apply w-12;
  }
  .invite-modal-agents .p-confirm-dialog-message {
    @apply ml-0 !important;
  }
  .hidden-input {
    visibility: hidden;
    position: absolute;
    left: -9999px;
    display: none;
  }
  .timeline-opposite-hide .p-timeline-event-opposite {
    display: none;
  }
  .insuranceProduct .p-tabview-nav-container{
    padding-right:calc(100% - 85%);
    @apply border-b-2 border-gray-300
}
}
